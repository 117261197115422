'use strict';

class Guest {
	public onDomContentsLoaded(event:any = null) {
		$('.mainvisual').addClass('show');
		$(window).on('resize', ()=> {
			this.resize();
		});
		this.resize();
	}
	public onContentsLoad(event:any = null) {
		this.resize();
	}
	public resize() {
		var wid = $(window).innerWidth();
		var hig = $(window).innerHeight();
		var scl = wid/1920 > hig/1080 ? wid/1920 : hig/1080;
		var vWid = 1920 * scl;
		var vHig = 1080 * scl;
		var offsetX = (wid-vWid)/2;
		var offsetY = (hig-vHig)/2;
		//
		$('.bg').css({
			width: vWid+'px', height: vHig+'px',
			top: offsetY+'px', left: offsetX + 'px',
		});
		//
		$('.fixed_bg').css({
			width: vWid+'px', height: vHig+'px',
			top: offsetY+'px', left: offsetX + 'px',
		});
	}
}

var guest = new Guest();
window['guest'] = guest;
if (document.readyState !== 'loading') {
	guest.onDomContentsLoaded();
} else {
	document.addEventListener('DOMContentLoaded', function(){window['guest'].onDomContentsLoaded();});
}
window.addEventListener('load',function(){window['guest'].onContentsLoad();});
